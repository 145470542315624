import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import MessageList from "@/components/Ticket/MessageList";
import MessageInputBox from "@/components/Ticket/MessageInputBox";
import {useCallback, useEffect, useState} from "react";
import {useLoadingStore} from "@/stores/loading";
import {getTicket as getTicketApi} from "@/api/ticket";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import {useProjectStore} from "@/stores/project";
import {useUserStore} from "@/stores/user";
import {
  addTicketDestination,
  getTicketDestinationBy,
} from "@/api/ticketDestination";
import {TicketDestinations, Ticket} from "@/components/Ticket/types";

export default function DataTicket() {
  const setLoading = useLoadingStore((state) => state.setLoading);
  const user = useUserStore((state) => state.user);
  const [selectedProject, selectedProjectCategory] = useProjectStore(
    (state) => [state.project, state.projectCategory]
  );

  const navigate = useNavigate();
  const params = useParams();
  const pathname = useLocation().pathname;
  const canTickets = Common.canTicket(selectedProjectCategory?.id, user);

  const [ticket, setTicket] = useState<Ticket>();
  const [ticketDestList, setTicketDestList] = useState<TicketDestinations[]>(
    []
  );

  const onClickTicketSearch = () => {
    // window.open(`${BASENAME}/ticket_search`)
    window.open(`/progress/${params.projectCategoryId}/ticket/search`);
  };

  const getTicket = useCallback(() => {
    if (selectedProject?.id) {
      Promise.allSettled([
        getTicketApi({data_id: Number(params.dataId)}),
        getTicketDestinationBy(selectedProject.id),
      ])
        .then((result) => {
          result.forEach((r, i) => {
            if (r.status === 'fulfilled') {
              i == 0 ? setTicket(r.value.data) : setTicketDestList(r.value.data.ticketDestinations);
            }
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (canTickets < Config.RESTRICTION_HISTORY_TICKET_VIEW.value) {
      navigate("/progress");
    }
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    getTicket();
  }, []);

  return (
    <div>
      <div className="container">
        <div>
          <div className="uk-margin-small-top">
            <table className="uk-width-medium">
              <tbody>
              <tr>
                <td className="uk-width-2-5 background-gray239">
                  ステータス
                </td>
                <td>{ticket?.status}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <button
            className="uk-button--m uk-button-refer uk-margin-small-top"
            onClick={onClickTicketSearch}
          >
            チケット検索
          </button>
          <MessageList ticket={ticket} className="uk-margin-top"/>
          <MessageInputBox
            ticket={ticket}
            getTicket={getTicket}
            ticketDestList={ticketDestList}
          />
        </div>
      </div>
    </div>
  );
}
