import {useFormContext, useWatch} from "react-hook-form";
import {basicMapStore} from "@/stores/basicMap";
import {Attribute} from "@/components/ProgressList/types";
import * as Common from "@/utils/common";
import {useEffect} from "react";
import {useMeshAttribute} from "@/components/Attributes/MeshAttribute/useMeshAttribute";

interface SecondaryMeshAutoFillProps {
  attributes: Attribute[];
}

export const SecondaryMeshAutoFill = ({attributes}: SecondaryMeshAutoFillProps) => {

  const {control, setValue} = useFormContext();
  const {fetchSecondaryMesh} = useMeshAttribute();

  const selectedArea = useWatch({
    control,
    name: "area_id",
  });

  const secondaryMeshNumberId = attributes.find(attr => Common.isSecondaryMesh(attr))?.id;
  const secondaryMeshNumberNameId = attributes.find(attr => Common.isSecondaryMeshName(attr))?.id;
  const formName = {
    secondaryMesh: `attributes.${secondaryMeshNumberId}`,
    secondaryMeshName: `attributes.${secondaryMeshNumberNameId}`
  }

  const watchSecondaryMeshNumber: string = useWatch({
    control,
    name: formName.secondaryMesh
  });

  useEffect(() => {
    const updateSecondaryMeshName = async () => {
      if (selectedArea && watchSecondaryMeshNumber?.length === 6) {
        const meshName = await fetchSecondaryMesh({
          code: watchSecondaryMeshNumber,
          area_id: selectedArea
        });
        setValue(formName.secondaryMeshName, meshName || undefined);
      }
    };

    updateSecondaryMeshName();
  }, [watchSecondaryMeshNumber])

  useEffect(() => {
    setValue(formName.secondaryMesh, undefined);
    setValue(formName.secondaryMeshName, undefined);
  }, [selectedArea])

  return null;
}