import React from 'react';
import ConfirmModal from "@/components/ConfirmModal";
import * as Config from '@/config';
import {useDrmPfDetail} from "@/containers/customer/drmPf/useDrmPf";

export default function DrmPfDetail() {
  const hooks = useDrmPfDetail();

  return (
    <>
      {hooks.drmPfInfo.map((item, index) => (
        <div key={index} className="info-tbl_wrap uk-margin-small-top">
          <table className="customer-info-tbl uk-width-1-1">
            <tbody>
            <tr>
              <th className="label">課金区分</th>
              <td className="content">{item.customer_payment_type_id}.{item.customer_payment_type}</td>
            </tr>
            <tr>
              <th className="label">承認状況</th>
              <td className="content">
                {hooks.approvalStatus == Config.CUSTOMER_STATUS_UNAPPROVED ? (
                  <button
                    type="button"
                    className="uk-button--m uk-button-primary"
                    onClick={() => {
                      hooks.setIsShowModal(true);
                    }}
                  >
                    承認
                  </button>
                ) : hooks.approvalStatus == Config.CUSTOMER_STATUS_APPROVAL ? (
                  <span>2.承認済
                    {item.approval_by != null && (<>&emsp;&emsp;承認者：{item.approval_by}&emsp;承認日：{item.approval_date}</>)} {/*無償以外*/}
                  </span>
                ) : null}
              </td>
            </tr>
            {item.customer_payment_type_id != null && item.customer_payment_type_id != Config.CUSTOMER_PAYMENT_TYPE_FREE && ( // 無償以外
              <>
                <tr>
                  <th className="label">決済方法</th>
                  <td className="content">{hooks.paymentMethod}</td>
                </tr>
                {item.payment_method == Config.PAYMENT_METHOD_CREDIT_CARD && ( // クレジットカード払い
                  <>
                    <tr>
                      <th className="label">クレジットカード登録状況</th>
                      <td className="content">{hooks.creditCardStatus}</td>
                    </tr>
                  </>
                )}
                {item.payment_method != Config.PAYMENT_METHOD_CREDIT_CARD && ( // クレジットカード払い以外
                  <>
                    <tr>
                      <th className="label">与信審査</th>
                      <td className="content">{hooks.creditStatus}</td>
                    </tr>
                  </>
                )}
              </>
            )}
            <tr>
              <th className="label">利用目的</th>
              <td className="content">{hooks.usePurpose}</td>
            </tr>
            {item.customer_type_id == Config.CUSTOMER_TYPE_NOT_ROAD_MANAGER &&
              item.use_purpose != null &&
              item.use_purpose != Config.USE_PURPOSE_ROAD_MANAGER_SYSTEM &&
              item.use_purpose != Config.USE_PURPOSE_NOT_ROAD_MANAGER_SYSTEM &&
              item.use_purpose != Config.USE_PURPOSE_ROAD_MANAGER_CONFIRM &&
              item.use_purpose != Config.USE_PURPOSE_OTHER && ( // 道路管理者以外 で 5.自社開発のシステム開発 の場合
                <>
                  <tr>
                    <th className="label">システム体系</th>
                    <td className="content">{hooks.systemStructure}</td>
                  </tr>
                </>
              )}
            <tr>
              <th className="label">利用目的詳細</th>
              <td className="content">
                {item.use_purpose_detail?.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br/>
                  </React.Fragment>
                ))}
              </td>
            </tr>
            <tr>
              <th className="label">利用終了日</th>
              <td className="content">{item.end_using_date}</td>
            </tr>
            <tr>
              <th className="label">DRM-PFの二次利用</th>
              <td className="content">{hooks.isSecondaryUse}</td>
            </tr>
            {item.is_secondary_use != undefined && item.is_secondary_use == Config.IS_SECONDARY_USE && (
              <>
                <tr>
                  <th className="label">二次利用方法</th>
                  <td className="content">{hooks.useMethod}</td>
                </tr>
              </>
            )}
            <tr>
              <th className="label">利用申請日</th>
              <td className="content">{hooks.applyUsingDate}</td>
            </tr>
            <tr>
              <th className="label">利用開始月<br/>※長期割引の起点月</th>
              <td className="content">{hooks.startUsingMonth}</td>
            </tr>
            {item.customer_payment_type_id != null && item.customer_payment_type_id != Config.CUSTOMER_PAYMENT_TYPE_FREE && ( // 無償以外
              <>
                <tr>
                  <th className="label">請求先コード</th>
                  <td className="content">{item.billing_code}</td>
                </tr>
                <tr>
                  <th className="label">請求先</th>
                  <td className="content">{hooks.billingType}</td>
                </tr>
                {item.billing_post_type != Config.BILLING_POST_TYPE_APPLICANT && ( // 請求先（申込者以外）
                  <>
                    <tr>
                      <th className="label">請求先 - 法人・団体名</th>
                      <td className="content">{item.billing_post_group_name}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 部署名・所属</th>
                      <td className="content">{item.billing_post_department_name}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 姓</th>
                      <td className="content">{item.billing_post_last_name}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 名</th>
                      <td className="content">{item.billing_post_first_name}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 電話番号</th>
                      <td className="content">{item.billing_post_phone_number}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 郵便番号</th>
                      <td className="content">{item.billing_post_zipcode}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 都道府県</th>
                      <td className="content">{item.billing_post_pref}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 市区町村</th>
                      <td className="content">{item.billing_post_city}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 町名番地</th>
                      <td className="content">{item.billing_post_house_number}</td>
                    </tr>
                    <tr>
                      <th className="label">請求先 - 建物名・部屋番号</th>
                      <td className="content">{item.billing_post_building_name}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <th className="label">事業体区分</th>
                  <td className="content">{hooks.businessType}</td>
                </tr>
                {item.business_entity_type == Config.BUSINESS_ENTITY_TYPE_CORPORATION && (
                  <>
                    <tr>
                      <th className="label">法人番号</th>
                      <td className="content">{item.corporate_number}</td>
                    </tr>
                  </>
                )}
              </>
            )}
            {item.customer_payment_type_id != Config.CUSTOMER_PAYMENT_TYPE_FREE && item.business_entity_type == Config.BUSINESS_ENTITY_TYPE_CORPORATION && ( // 法人
              <>
                <tr>
                  <th className="label">会社代表者名</th>
                  <td className="content">{item.company_representative_name}</td>
                </tr>
                <tr>
                  <th className="label">会社URL</th>
                  <td className="content">{item.company_url}</td>
                </tr>
              </>
            )}
            <tr>
              <th className="label">利用規約</th>
              <td className="content">{hooks.isAgree}</td>
            </tr>
            {item.customer_payment_type_id != null && item.customer_payment_type_id != Config.CUSTOMER_PAYMENT_TYPE_FREE && ( // 無償以外
              <>
                <tr>
                  <th className="label">請求業務の委託</th>
                  <td className="content">{hooks.isBilling}</td>
                </tr>
              </>
            )}
            <tr>
              <th className="label height-5rem">特記事項</th>
              <td className="content">{item.special_notes}</td>
            </tr>
            </tbody>
          </table>
          <table className="customer-info-tbl uk-width-1-1">
            <tbody>
            <tr>
              <th className="label height-5rem">備考（内部用）</th>
              <td className="content">{item.remarks}</td>
            </tr>
            </tbody>
          </table>
        </div>
      ))}
      <ConfirmModal
        text="承認してもよろしいですか？"
        confirmButtonText="承認"
        isShow={hooks.isShowModal}
        onConfirm={hooks.onConfirm}
        onCancel={() => {
          hooks.setIsShowModal(false);
        }}
      />
    </>
  )
}
export const drm_pf_info_items = [
  {label: '承認状況', content: "未承認"},
]
