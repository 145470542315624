import React, {useCallback, useMemo, useState} from "react";
import {DrmPfApiUsagesOptions} from "@/containers/customer/usageHistory/useDrmPfApiUsages";
import {AllLogs} from "@/containers/customer/drmPf/types";
import * as Config from '@/config'
import ReactPaginate from "react-paginate";
import {ToolTipTd} from "@/components/Customer/UsageHistory/ToolTipTd";
import {Spinner} from "@/components/Spinner";

interface DisplayLogDataProps {
  logs: AllLogs[];
  paging: {
    page: number;
    limit: number;
    count: number;
    pageCount: number;
  };
  onPageChange: (page: string) => void;
  onSelectedPageSize: (pageSize: string) => void;
  onSearchLogs: () => void;
  loading: boolean;
}

export const DisplayLogData = ({logs, paging, onPageChange, onSelectedPageSize, onSearchLogs, loading}: DisplayLogDataProps) => {
  const UsagesOptions = DrmPfApiUsagesOptions();
  const handlePageChange = useCallback((selectedItem: { selected: number }) => {
    onPageChange(String(selectedItem.selected + 1));
  }, [onPageChange]);
  const [open, setOpen] = useState(true);

  const forcePage = useMemo(() => (paging?.page ?? 1) - 1, [paging?.page]);
  return (
    <div className="uk-margin-top toggle-wrapper uk-position-relative uk-margin-bottom">
      {loading && open && <Spinner/>}
      <button type="button"
              className="uk-button uk-button-text uk-text-primary"
              onClick={() => setOpen((prev) => !prev)}
      >
        {open ? "▲" : "▼"} APIログ 一覧
      </button>
      <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${open ? "visible" : "hidden"}`}>
        <div className="uk-flex uk-flex-right uk-margin-small-bottom">
          <button type="button" className="uk-button--l uk-button-refer" onClick={() => onSearchLogs()}>APIログ 検索
          </button>
        </div>
        <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
          <div className="uk-inline">
            <select className="table-length" defaultValue={"100"}
                    onChange={(e) => onSelectedPageSize(e.target.value)}>
              <option value="" disabled>表示件数</option>
              {Config.pageSizeOptions.map((o: any) => {
                return <option key={`page-size-options${o.value}`} value={o.value}>{o.label}</option>
              })}
            </select>
          </div>
          <span className="uk-text-right uk-margin-remove-bottom">
          ※こちらの小計はボリュームディスカウント前の金額となります。
          </span>
        </div>

        <div className="uk-width-1-1 usage-tbl-wrap mt-5 uk-margin-bottom">
          <table className="usage-tbl">
            <thead>
            <tr>
              {UsagesOptions.drm_pf_api_usages_th.map((item, index) => {
                return (
                  <th className="label uk-text-center" key={index}>{item.label}</th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {logs.length !== 0 && logs.map((item, index) => (
              <tr key={index}>
                <ToolTipTd>{item.customer_number}</ToolTipTd>
                <ToolTipTd>{item.customer_type}</ToolTipTd>
                <ToolTipTd>{item.customer_group_type}</ToolTipTd>
                <ToolTipTd>{item.group_name}</ToolTipTd>
                <ToolTipTd>{item.department_name}</ToolTipTd>
                <ToolTipTd>{item.start_date}</ToolTipTd>
                <ToolTipTd>{item.end_date}</ToolTipTd>
                <ToolTipTd>{item.ip_address}</ToolTipTd>
                <ToolTipTd>{item.api_id}</ToolTipTd>
                <ToolTipTd className="uk-text-center">{item.processed_result}</ToolTipTd>
                <ToolTipTd className="uk-text-center">{item.processed_kind}</ToolTipTd>
                <ToolTipTd className="uk-text-center">{item.edition_information}版</ToolTipTd>
                <ToolTipTd className="uk-text-right">{item.data_number} 件</ToolTipTd>
                <ToolTipTd className="uk-text-right">¥ {item.unit_price}</ToolTipTd>
                <ToolTipTd className="uk-text-center">{item.is_billing_str}</ToolTipTd>
                {item.is_temporary_free === 1 ?
                  <ToolTipTd className="uk-text-right"> -{item.data_number} 件</ToolTipTd> :
                  <ToolTipTd className="uk-text-right"></ToolTipTd>}
                <ToolTipTd className="uk-text-right">¥ {item.subtotal}</ToolTipTd>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="uk-text-center mt-5">
          全{paging?.count}件（{paging?.page}/{paging?.pageCount}）
        </div>
        <div className="pagination-container">
          <a onClick={() => {
            onPageChange('1')
          }}>FIRST</a>
          <ReactPaginate
            forcePage={forcePage} // ReactPaginate→0始まり CakePHP→1始まり
            onPageChange={handlePageChange}
            pageRangeDisplayed={5}
            pageCount={paging?.pageCount}
            renderOnZeroPageCount={null}
            containerClassName='pagination'
            activeLinkClassName="uk-text-emphasis"
            previousLabel='<'
            nextLabel='>'
            breakLabel='...'
          />
          <a onClick={() => {
            onPageChange(String(paging?.pageCount))
          }}>LAST</a>
        </div>
      </div>
    </div>
  )
}