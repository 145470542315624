
interface SpinnerProps {
  ratio?: string | number;
}
/**
 * 親要素にclassName="uk-position-relative"を設定
 */
export const Spinner = (props: SpinnerProps) => {
  const ratio = props.ratio ? props.ratio : "2";
  return (
    <div className="uk-spinner-container">
      <div className="uk-position-center">
        <span uk-spinner={`ratio: ${ratio}`}></span>
      </div>
    </div>
  )
}