import React from "react";
import {axios} from "@/lib/axios";
import {MeshInfo} from "@/containers/data/Attributes/AttributeInput";

interface MeshResponse {
  name: string;
}

interface FetchMeshOptions {
  code: string;
  area_id: number | string;
  setMeshInfo?: React.Dispatch<React.SetStateAction<MeshInfo | null>>;
}

export function useMeshAttribute() {

  const fetchSecondaryMesh = async ({
                                      code,
                                      area_id,
                                      setMeshInfo
                                    }: FetchMeshOptions): Promise<string> => {
    try {
      const response = await axios.get<MeshResponse>(
        '/api/v1/dicRoadAttributes/getDicSecondaryMeshes',
        {params: {code, area_id}}
      );

      const meshName = response.data.name || '';

      if (meshName && setMeshInfo) {
        setMeshInfo({
          id: -1,
          name: meshName,
          secondary_mesh_code: code,
        });
      }

      return meshName;
    } catch (error) {
      return '';
    }
  };

  return {fetchSecondaryMesh}
}