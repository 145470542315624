export function useCustomer() {
    const CustomersListHeader = [
      {
        headerName:'顧客番号',
        sortItemName: 'customer_number',
      },
      {
        headerName:'ログインID',
        sortItemName: 'login_id',
      },
      {
        headerName:'承認状況',
        sortItemName: 'approval_status',
      },
      {
        headerName:'課金区分',
        sortItemName: 'customer_payment_type_id',
      },
      {
        headerName:'道路管理者区分',
        sortItemName: 'customer_type_id',
      },
      {
        headerName:'団体区分',
        sortItemName: 'customer_group_type_id',
      },
      {
        headerName:'法人・団体名',
        sortItemName: 'group_name',
      },
      {
        headerName:'部署名',
        sortItemName: 'department_name',
      },
      {
        headerName:'課名',
        sortItemName: 'section_name',
      },
      {
        headerName:'室名または係名',
        sortItemName: 'unit_name',
      },
      {
        headerName:'姓',
        sortItemName: 'last_name',
      },
      {
        headerName:'名',
        sortItemName: 'first_name',
      },
      {
        headerName:'利用終了日',
        sortItemName: 'end_using_date',
      },
    ];

    return {CustomersListHeader}
}