import ConfirmModal from "@/components/ConfirmModal";
import { MESSAGE_NO_E39 } from "@/config";
import { useLoadingStore } from "@/stores/loading";
import { FC, useState } from "react";
import SubHeader from "../../../components/SubHeader";
import { useTicketDestinations } from "./shared/useTicketList";
import { isValidEmail } from "@/utils/common";
import {TicketDestinations} from "@/components/Ticket/types";
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";
import * as Config from "@/config";

const TicketDestinationList: FC = () => {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_TICKET_DESTINATION_MANAGEMENT);
  const setLoading = useLoadingStore((state) => state.setLoading);

  const [newTicketDest, setNewTicketDest] = useState<TicketDestinations | null>(
    null
  );
  const [modifyTicket, setModifyTicket] = useState<TicketDestinations | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [removeTicket, setRemoveTicket] = useState<TicketDestinations | null>(
    null
  );

  const breadCrumbs = [
    { title: "管理画面", path: "/admin" },
    { title: "チケット管理" },
  ];

  const [isShowModal, setIsShowModal] = useState(false);
 
  const onClickNew = () => {
    setNewTicketDest({
      id: 0,
      project_id: -1,
      name: "",
      email_address: "",
    });
  };

  const onClickModify = (item: any) => {
    setModifyTicket({
      id: item.id,
      project_id: item.project_id,
      name: item.name,
      email_address: item.email_address,
    });
  };

  const onClickAdd = () => {
    if (!newTicketDest) {
      return;
    }
    if (newTicketDest.project_id < 0 || newTicketDest.project_id === null) {
      setErrorMessage("プロジェクトを選択してください");
      return;
    }
    if (newTicketDest.name === "") {
      setErrorMessage("送信先名を入力してください");
      return;
    }
    if (newTicketDest.email_address === "") {
      setErrorMessage("送信先アドレスを入力してください");
      return;
    }
    if (!isValidEmail(newTicketDest.email_address)) {
      setErrorMessage("送信先アドレスが不正です");
      return;
    }

    addTicketDest(newTicketDest).then(() => setNewTicketDest(null));
  };

  const onClickUpdate = () => {
    setIsShowModal(true);
  };
  const onClickCancel = () => {
    setModifyTicket(null);
  };

  const onClickOKUpdate = () => {
    if (!modifyTicket) {
      return;
    }
    if (modifyTicket.project_id < 0 || modifyTicket.project_id === null) {
      setErrorMessage("プロジェクトを選択してください");
      return;
    }
    if (modifyTicket.name === "") {
      setErrorMessage("送信先名を入力してください");
      return;
    }
    if (modifyTicket.email_address === "") {
      setErrorMessage("送信先アドレスを入力してください");
      return;
    }
    if (!isValidEmail(modifyTicket.email_address)) {
      setErrorMessage("送信先アドレスが不正です");
      return;
    }

    editTicketDest(modifyTicket).then(() => setModifyTicket(null));
    setIsShowModal(false);
  };

  const remove = () => {
    if (!removeTicket) return;

    removeTicketDest(removeTicket).then(() => setRemoveTicket(null));
  };

  const { projects, ticketDestinations, addTicketDest, editTicketDest, removeTicketDest } =
    useTicketDestinations(setLoading, () => setErrorMessage(MESSAGE_NO_E39));
  
  return (
    <>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="チケット管理" />

        <div className="uk-flex-right uk-flex uk-flex-middle">
          <button
            className="uk-button--m uk-button-refer uk-margin-left"
            onClick={onClickNew}
          >
            新規作成
          </button>
        </div>
      </div>
      <div className="container">
        <div className="admin-tbl uk-margin-small-top">
          <table className="uk-width-1-1">
            <thead>
              <tr>
                <th className="uk-text-center width-40">
                  <div className="border-box" />
                  No.
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  プロジェクト
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  送信先名
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  送信先アドレス
                </th>
                <th className="uk-text-center width-120">
                  <div className="border-box" />
                  編集
                </th>
                <th className="uk-text-center width-120">
                  <div className="border-box" />
                  削除
                </th>
              </tr>
            </thead>
            <tbody>
              {ticketDestinations && ticketDestinations.map((item, index) => (
                    <tr
                    style={{
                      height: "50px",
                    }}
                  >
                  {modifyTicket && modifyTicket.id == item.id ? (
                    <>
                      <td className="uk-text-center width-40">
                        <div className="border-box" />
                        {(ticketDestinations ?? []).length + 1}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        <select
                          value={modifyTicket.project_id}
                          onChange={(e) =>
                            setModifyTicket({
                              ...modifyTicket,
                              project_id: Number(e.target.value),
                            })
                          }
                        >
                          <option>未選択</option>
                          {projects?.map((project) => (
                            <option key={project.id} value={project.id}>
                              {project.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        <input
                          value={modifyTicket.name}
                          onChange={(e) =>
                            setModifyTicket({
                              ...modifyTicket,
                              name: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        <input
                          value={modifyTicket.email_address}
                          onChange={(e) =>
                            setModifyTicket({
                              ...modifyTicket,
                              email_address: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td className="uk-text-center" colSpan={2}>
                        <div className="border-box" />
                          <button
                            className="uk-button--m uk-button-cancel"
                            onClick={onClickCancel}
                          >
                          キャンセル
                          </button>
                          <button
                            className="uk-button--m uk-button-refer uk-margin-left width-60"
                            onClick={onClickUpdate}
                          >
                          更新
                          </button>
                      </td>
                      </>
                  ) : (
                    <>
                      <td className="uk-text-center width-40">
                        <div className="border-box" />
                        {index + 1}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {
                          projects?.find(
                            (project) => project.id === item.project_id
                          )?.name
                        }
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        {item.name}
                      </td>
                      <td className="uk-text-left">
                        <div className="border-box" />
                        {item.email_address}
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        <a onClick={() => onClickModify(item)}>編集</a>
                      </td>
                      <td className="uk-text-center">
                        <div className="border-box" />
                        <a onClick={() => setRemoveTicket(item)}>削除</a>
                      </td>
                    </>
                  )}
                  </tr>
              ))}
              {newTicketDest && (
                <tr
                  style={{
                    height: "50px",
                  }}
                >
                  <td className="uk-text-center width-40">
                    <div className="border-box" />
                    {(ticketDestinations ?? []).length + 1}
                  </td>
                  <td className="uk-text-center">
                    <div className="border-box" />
                    <select
                      value={newTicketDest.project_id}
                      onChange={(e) =>
                        setNewTicketDest({
                          ...newTicketDest,
                          project_id: Number(e.target.value),
                        })
                      }
                    >
                      <option>未選択</option>
                      {projects?.map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="uk-text-center">
                    <div className="border-box" />
                    <input
                      value={newTicketDest.name}
                      onChange={(e) =>
                        setNewTicketDest({
                          ...newTicketDest,
                          name: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="uk-text-center">
                    <div className="border-box" />
                    <input
                      value={newTicketDest.email_address}
                      onChange={(e) =>
                        setNewTicketDest({
                          ...newTicketDest,
                          email_address: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="uk-text-center" colSpan={2}>
                    <div className="border-box" />
                    <button
                      className="uk-button--m uk-button-refer width-60"
                      onClick={onClickAdd}
                    >
                      追加
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={!!errorMessage}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        />
      )}
      {removeTicket && (
        <ConfirmModal
          text={"削除してもよろしいでしょうか？"}
          confirmButtonText="OK"
          isShow={!!removeTicket}
          onConfirm={remove}
          onCancel={() => {
            setRemoveTicket(null);
        }}
      />
      )}
      {isShowModal && (
        <ConfirmModal
          text={"更新してもよろしいでしょうか？"}
          confirmButtonText="OK"
          isShow={!!modifyTicket}
          onConfirm={onClickOKUpdate}
          onCancel={() => {
              //setModifyTicket(null);
              setIsShowModal(false);
          }}
        />      
      )}
    </>
  );
};

export default TicketDestinationList;
