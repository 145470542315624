import SubHeader from "@/components/SubHeader";
import React, {useEffect, useState} from "react";
import {deleteLayer, getLayers, postLayer} from "@/api/layers";
import {useLoadingStore} from "@/stores/loading";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import ConfirmModal from "@/components/ConfirmModal";
import {useForm} from "react-hook-form";
import {Layer} from "@/containers/admin/Layers/types";
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";

type ModalMessage = {
  message: string;
  deleteId: number;
}

type Params = {
  id: number;
  name: string;
  url: string;
}

const InitParams = {
  name: '',
  url: ''
}

export const Layers = () => {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_LAYER_MANAGEMENT);
  const setLoading = useLoadingStore((state) => state.setLoading);

  const breadCrumbs = [
    {title: '管理画面', path: '/admin'},
    {title: '作図管理'}
  ];
  const [layers, setLayers] = useState([] as Layer[]);
  const [modalMessage, setModalMessage] = useState<ModalMessage | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modifyLayer, setModifyLayer] = useState<Layer | null>(
    null
  );

  const {
    register,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: {errors}
  } = useForm<Params>();

  const getData = () => {

    getLayers().then(result => setLayers(result.data.layers))
      .finally(() => setLoading(false));
  }

  const onDelete = (id: number) => {
    setModalMessage(null);
    setLoading(true);
    deleteLayer(id).then(result => {
      getData();
    })
  }

  const onPost = () => {
    setLoading(true);
    clearErrors();
    const params = getValues();
    postLayer(params.id, params.name, params.url).then(result => {
      setShowForm(false);
      reset(InitParams);
      getData();
    }).catch(error => {
      const message = error.response.data.message;
      if (message.name) setError('name', {message: message.name});
      if (message.url) setError('url', {message: message.url});
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const onClickModify = (layer: any) => {
    setModifyLayer({
      id: layer.id,
      name: layer.name,
      url: layer.url,
    });
  };

  const onClickUpdate = () => {
    setIsShowModal(true);
  };

  const onClickCancel = () => {
    setModifyLayer(null);
  };

  const onClickOKUpdate = () => {
    if (!modifyLayer) {
      return;
    }
    if (modifyLayer.name === "") {
      setErrorMessage("レイヤー名を入力してください");
      return;
    }
    if (modifyLayer.url === "") {
      setErrorMessage("レイヤーURLを入力してください");
      return;
    }
    editLayer(modifyLayer);
    setModifyLayer(null);
    setIsShowModal(false);
  };

  const editLayer = (modifyLayer: any) =>{
    setLoading(true);
    clearErrors();
    postLayer(modifyLayer.id, modifyLayer.name, modifyLayer.url).then(result => {
      setShowForm(false);
      getData();
    }).catch(error => {
      const message = error.response.data.message;
      if (message.name) setError('name', {message: message.name});
      if (message.url) setError('url', {message: message.url});
      setLoading(false)
    })
}
  return (
    <>
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="作図管理"/>
        <div className="uk-text-right uk-margin-small-bottom">
        </div>
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div></div>
          <button className="uk-button--m uk-button-refer uk-margin-left"
                  onClick={() => setShowForm(true)}>新規作成
          </button>
        </div>
      </div>
      <div className="container">
        <div className="admin-tbl uk-margin-small-top">
          <table className="uk-width-1-1">
            <thead>
            <tr>
              <th className="uk-text-center width-40">
                <div className="border-box"/>
                No.
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                レイヤー名
              </th>
              <th className="uk-text-center">
                <div className="border-box"/>
                レイヤーURL
              </th>
              <th className="uk-text-center width-120">
                <div className="border-box"/>
                編集
              </th>
              <th className="uk-text-center width-120">
                <div className="border-box"/>
                削除
              </th>
            </tr>
            </thead>
            <tbody>
            {layers && layers.map((layer, index) => (
              <tr>
              {modifyLayer && modifyLayer.id == layer.id? (
                <>
                  <td className="uk-text-center width-40">
                    <div className="border-box" />
                    {index + 1}
                  </td>
                  <td className="uk-text-left">
                    <div className="border-box" />
                    <input
                      value={modifyLayer.name}
                      onChange={(e) =>
                        setModifyLayer({
                          ...modifyLayer,
                          name: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="uk-text-left">
                    <div className="border-box" />
                    <input
                      value={modifyLayer.url}
                      onChange={(e) =>
                        setModifyLayer({
                          ...modifyLayer,
                          url: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="uk-text-center" colSpan={2}>
                    <div className="border-box" />
                    <button
                      className="uk-button--m uk-button-cancel"
                      onClick={onClickCancel}
                    >
                      キャンセル
                    </button>
                    <button
                      className="uk-button--m uk-button-refer uk-margin-left width-60"
                      onClick={onClickUpdate}
                    >
                      更新
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td className="uk-text-center width-40">
                    <div className="border-box"/>
                    {index + 1}
                  </td>
                  <td className="uk-text-left">
                    <div className="border-box"/>
                    {layer.name}
                  </td>
                  <td className="uk-text-left">
                    <div className="border-box"/>
                    {layer.url}
                  </td>
                  <td className="uk-text-center">
                      <div className="border-box" />
                      <a onClick={() => onClickModify(layer)}>編集</a>
                    </td>
                  <td className="uk-text-center">
                    <div className="border-box"/>
                    <a onClick={() => setModalMessage({message: Config.MESSAGE_NO_E11, deleteId: layer.id})}>削除</a>
                  </td>
                  </>
              )}
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        {showForm &&
          <div className="layer-add-tbl-wrap">
            <table className="layer-add-tbl">
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <input {...register('name')}/>
                    {errors.name && (<div className="error">{errors.name.message}</div>)}
                  </td>
                  <td>
                    <input {...register('url')}/>
                    {errors.url && (<div className="error">{errors.url.message}</div>)}
                  </td>
                  <td>
                    <button className="uk-button--m uk-button-refer" onClick={onPost}>保存</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={!!errorMessage}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        />
      )}
      {isShowModal && (
        <ConfirmModal
          text={"更新してもよろしいでしょうか？"}
          confirmButtonText="OK"
          isShow={!!modifyLayer}
          onConfirm={onClickOKUpdate}
          onCancel={() => {
            setIsShowModal(false);
          }}
        />
      )}
      <ConfirmModal
        text={modalMessage?.message ?? ''}
        confirmButtonText="OK"
        isShow={!!modalMessage}
        onConfirm={() => {
          onDelete(modalMessage?.deleteId as number);
        }}
        onCancel={() => setModalMessage(null)}
      />
    </div>
    </>
  )
}
