import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";
import ConfirmModal from "@/components/ConfirmModal";
import {
  IS_BILLING_FLG,
  MESSAGE_NO_E39,
} from "@/config";
import {useNavigate, useParams} from 'react-router-dom';
import {DrmPfApiUsagesOptions, useDrmPfApiUsages} from "@/containers/customer/drmPf/usages/useDrmPfApiUsages";
import {Bills, Fees, Logs} from "@/containers/customer/drmPf/types";
import {axios} from "@/lib/axios";
import qs from 'qs';
import {useDrmPfApiUsages as useDrmPfApiUsagesExcel} from "@/containers/customer/usageHistory/useDrmPfApiUsages";
import {useDrmPfDetail} from "@/containers/customer/drmPf/useDrmPf";
import ReactPaginate from "react-paginate";
import {Spinner} from "@/components/Spinner";

export default function APIUsagesDetail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const UsagesOptions = DrmPfApiUsagesOptions();
  const {CurrentDate} = useDrmPfApiUsages();
  useDrmPfDetail();
  const customerId = useParams().customerId;
  const [setLoading, isLoading] = useLoadingStore(state => [state.setLoading, state.isLoading]);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const {downloadExcel} = useDrmPfApiUsagesExcel();

  const [billsOpen, setBillsOpen] = useState(true);
  const [open, setOpen] = useState(true);
  const [logsOpen, setLogsOpen] = useState(true);
  
  const [logs, setLogs] = useState<Logs[]>([]);
  const [fees, setFees] = useState<Fees[]>([]);
  const [bills, setBills] = useState<Bills[]>([]);
  const [billingFlg, setBillingFlg] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [selectedPageSize, setSelectedPageSize] = useState<string | ''>('100');

  const pageSizeOptions = [
    {
      value: '25',
      label: '25件',
    },
    {
      value: '50',
      label: '50件',
    },
    {
      value: '100',
      label: '100件',
    },
    {
      value: '200',
      label: '200件',
    },
  ]

  // 検索項目
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [pageSize, setPageSize] = useState<string>("");
  const [page, setPage] = useState<string>("");

  const [tableLoading, setTableLoading] = useState<{ log: boolean, fee: boolean, bill: boolean }>({
    log: false,
    fee: false,
    bill: false
  });

  useEffect(() => {
    // URLパラメータから検索条件を取得し、状態に反映する
    const params = qs.parse(searchParams.toString(), {ignoreQueryPrefix: true});
    //console.log(params);
    if (typeof params.from_date === 'string') {
      setFromDate(params.from_date);
    }
    if (typeof params.to_date === 'string') {
      setToDate(params.to_date);
    }
    if (typeof params.page_size === 'string') {
      setPageSize(params.page_size);
    }
    if (typeof params.page === 'string') {
      setPage(params.page);
    }
  }, [searchParams]);

  //表示数変更時処理
  const onSelectedPageSize = (pageSize: string) => {
    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
      page_size: pageSize,
      page: page
    }
    fetchLogs(params);
  }

  //ページ数移動時処理
  const onPageChange = (page: string) => {
    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
      page_size: pageSize,
      page: page
    }
    fetchLogs(params);
  }

  // 検索結果
  const [paging, setPaging] = useState<any>(undefined);
  const forcePage = useMemo(() => (paging?.page ?? 1) - 1, [paging?.page]);
  const handlePageChange = useCallback((selectedItem: { selected: number }) => {
    onPageChange(String(selectedItem.selected + 1));
  }, [onPageChange]);

  const navigate = useNavigate();

  const onClickToggleBills = () => setBillsOpen((prev) => !prev);
  const onClickToggle = () => setOpen((prev) => !prev);
  const onClickToggleLogs = () => setLogsOpen((prev) => !prev);

  const onSearch = () => {
    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
      page_size: pageSize ? pageSize : 100,
      page: page ? page : 1
    }
    fetchBills();
    fetchFees();
    fetchLogs(params);
  }

  const onSearchLogs = () => {
    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
      page_size: pageSize ? pageSize : 100,
      page: page ? page : 1
    }
    fetchLogs(params);
  }

  // 検索処理
  // API内訳
  function fetchFees() {
    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
      page_size: pageSize ? pageSize : 100,
      page: page ? page : 1
    }
    setTableLoading(prev => ({...prev, fee: true}));
    axios.get('/api/v1/customers/getDrmPfapiGroups', {
      params: params
    }).then(result => {
      setFees(result.data.apiGroup);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setTableLoading(prev => ({...prev, fee: false}));
    });
  }

  // 請求データ
  function fetchBills() {
    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
      page_size: pageSize ? pageSize : 100,
      page: page ? page : 1
    }
    setTableLoading(prev => ({...prev, bill: true}));
    axios.get('/api/v1/customers/getDrmPfApiUsageBills', {
      params: params
    }).then(result => {
      setBills(result.data.billData);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setTableLoading(prev => ({...prev, bill: false}));
    });
  }

  // APIログ
  function fetchLogs(params: any) {
    setTableLoading(prev => ({...prev, log: true}));
    axios.get('/api/v1/customers/getDrmPfApiLogs', {
      params: params
    }).then(result => {
      setSearchParams(qs.stringify(result.data.params));
      setLogs(result.data.displayLogData);
      setFromDate(result.data.from_date);
      setToDate(result.data.to_date);
      setPaging(result.data.paging);
      if (result.data.logData[0]['is_billing'] != null) {
        const billig_flg = IS_BILLING_FLG.find((output, index) => {
          return output.value == result.data.logData[0]['is_billing'];
        });
        setBillingFlg(billig_flg?.label || '');
      }
      if (typeof params.page_size === 'string') {
        setSelectedPageSize(params.page_size);
      }
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setTableLoading(prev => ({...prev, log: false}));
    });
  }

  const onExcel = () => {
    const params = {
      customer_id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
    }
    setLoading(true);
    const postParams: { [key: string]: any; } = {
      'params': params,
    };

    axios.post('/api/v1/customers/exportUsageHistory', postParams, {
      responseType: 'blob',
    })
      .then(response => {
        downloadExcel(response);
      })
      .catch(error => {
        setMessage(MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="info-tbl_wrap uk-margin-small-top">
      <table className="API-table uk-width-1-1">
        <tbody>
        <tr>
          <th className="label uk-text-center uk-background-muted">照会年月日</th>
          <td className="content uk-flex uk-flex-between">
            <div>
              <div className="uk-inline">
                <input
                  name="to_date"
                  type="date"
                  className="uk-width-1-1"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="uk-inline">
                ～
              </div>
              <div className="uk-inline">
                <input
                  name="from_date"
                  type="date"
                  className="uk-width-1-1"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="uk-inline uk-margin-large-left">
                <button type="button" className="uk-button--m uk-button-refer" onClick={() => onSearch()}>全検索</button>
              </div>
            </div>
            <div className="uk-flex-right">
              <button className="uk-button--m uk-button-refer uk-margin-left" onClick={() => onExcel()}>Excel出力
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div className="uk-width-1-1 uk-margin-top uk-overflow-auto uk-position-relative">
        {tableLoading.bill && billsOpen && <Spinner/>}
        <button type="button"
                className="uk-button uk-button-text uk-text-primary"
                onClick={onClickToggleBills}
        >
          {billsOpen ? "▲" : "▼"} API請求情報 一覧
        </button>
        <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${billsOpen ? "visible" : "hidden"}`}>
          <div className="uk-flex uk-flex-right uk-margin-small-bottom">
            <button type="button" className="uk-button--l uk-button-refer" onClick={() => fetchBills()}>API請求情報 検索</button>
          </div>
          <table className="API-table">
            <thead>
            <tr>
              {UsagesOptions.drm_pf_api_summary_th.map((item, index) => {
                const replaced_label = item.label.split(/(\n)/).map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item.match(/\n/) ? <br/> : item}
                    </React.Fragment>
                  );
                });
                const style: React.CSSProperties = {
                  width: item.width
                };
                return (
                  <th className={`label uk-text-center ${item.bold && "uk-text-bold"}`}
                      style={style}
                      rowSpan={item.rowspan}
                      colSpan={item.colspan}
                      key={index}
                  >
                    {replaced_label}
                  </th>
                );
              })}
            </tr>
            <tr>
              {UsagesOptions.drm_pf_api_summary_th_2nd.map((item, index) => {
                const style: React.CSSProperties = {
                  width: item.width
                };
                return (
                  <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {Object.values(bills).length !== 0 && Object.values(bills).map((item, index) => (
              <tr key={index}>
                <td className="uk-text-center">{item.paymentType}</td>
                <td className="uk-text-right">¥ {item.basicFee}</td>
                <td className="uk-text-right">{item.validTotalAll} 件</td>
                <td className="uk-text-right">¥ {item.undiscountPriceTotal}</td>
                <td className="uk-text-right">¥ {item.discountAmount}</td>
                <td className="uk-text-right">{item.realDiscountRate} %</td>
                <td className="uk-text-right">¥ {item.discountPriceTotal}</td>
                <td className="uk-text-right">{item.rate} %</td>
                <td className="uk-text-right">{item.termDiscounts} %</td>
                <td className="uk-text-right">¥ {item.allDiscountPriceTotal}</td>
                <td className="uk-text-right uk-text-bold">¥ {item.billingAmount}</td>
                <td className="uk-text-right uk-text-bold">{item.tax} %</td>
                <td className="uk-text-right uk-text-bold">¥ {item.includeTax}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="uk-width-1-1 uk-margin-top uk-overflow-auto uk-position-relative">
        {tableLoading.fee && open && <Spinner/>}
        <button type="button"
                className="uk-button uk-button-text uk-text-primary"
                onClick={onClickToggle}
        >
          {open ? "▲" : "▼"} API利用料 内訳
        </button>
        <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${open ? "visible" : "hidden"}`}>
          <div className="uk-flex uk-flex-right uk-margin-small-bottom">
            <button type="button" className="uk-button--l uk-button-refer" onClick={() => fetchFees()}>API利用料
              検索
            </button>
          </div>
          <table className="API-table uk-width-auto">
            <thead>
            <tr>
              {UsagesOptions.drm_pf_api_details_th.map((item, index) => {
                const style: React.CSSProperties = {
                  width: item.width
                };
                return (
                  <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {Object.values(fees).length !== 0 && Object.values(fees).map((item, index) => (

              <tr key={item.api_id}>
                <td>{item.api_id}</td>
                <td className="uk-text-right">{item.total} 件</td>
                <td className="uk-text-right">{item.temporary_free_total} 件</td>
                <td className="uk-text-right">{item.valid_total} 件</td>
                <td className="uk-text-right">¥ {item.unit_price}</td>
                <td className="uk-text-right">¥ {item.subtotal}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="uk-width-1-1 uk-margin-top uk-margin-bottom uk-overflow-auto uk-position-relative">
        {tableLoading.log && logsOpen && <Spinner/>}
        <button type="button"
                className="uk-button uk-button-text uk-text-primary"
                onClick={() => setLogsOpen((prev) => !prev)}
        >
          {logsOpen ? "▲" : "▼"} APIログ 一覧
        </button>
        <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${logsOpen ? "visible" : "hidden"}`}>
          <div className="uk-flex uk-flex-right">
            <button type="button" className="uk-button--l uk-button-refer" onClick={() => onSearchLogs()}>APIログ 検索
            </button>
          </div>
          <div className="uk-flex-between uk-flex uk-flex-middle">
            <div className="uk-inline">
              <select className="table-length" defaultValue={selectedPageSize}
                      onChange={(e) => onSelectedPageSize(e.target.value)}>
                <option value="" disabled>表示件数</option>
                {pageSizeOptions.map((o: any, index) => {
                  return <option key={`customers-list-page-size-${index}`} value={o.value}>{o.label}</option>
                })}
              </select>
            </div>
            <p className="uk-text-right uk-margin-remove-bottom">
              ※こちらの小計はボリュームディスカウント前の金額となります。
            </p>
          </div>
          <div className="uk-width-1-1 uk-overflow-auto uk-margin-bottom uk-position-relative">
            <table className="API-table">
              <thead>
              <tr>
                {UsagesOptions.drm_pf_api_usages_th.map((item, index) => {
                  const style: React.CSSProperties = {
                    width: item.width
                  };
                  return (
                    <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                  );
                })}
              </tr>
              </thead>
              <tbody>
              {logs.length !== 0 && logs.map((item, index) => (
                <tr key={index}>
                  <td>{item.start_date}</td>
                  <td>{item.end_date}</td>
                  <td>{item.ip_address}</td>
                  <td>{item.api_id}</td>
                  <td className="uk-text-center">{item.processed_result}</td>
                  <td className="uk-text-center">{item.processed_kind}</td>
                  <td className="uk-text-center">{item.edition_information}版</td>
                  <td className="uk-text-right">{item.data_number} 件</td>
                  <td className="uk-text-right">¥ {item.unit_price}</td>
                  <td className="uk-text-center">{item.is_billing_str}</td>
                  {item.is_temporary_free === 1 ? <td className="uk-text-right"> -{item.data_number} 件</td> :
                    <td className="uk-text-right"></td>}
                  <td className="uk-text-right">¥ {item.subtotal}</td>
                </tr>
              ))}
              </tbody>
            </table>

            <div className="uk-text-center mt-5">
              全{paging?.count}件（{paging?.page}/{paging?.pageCount}）
            </div>
            <div className="pagination-container">
              <a onClick={() => {
                onPageChange('1')
              }}>FIRST</a>
              <ReactPaginate
                forcePage={forcePage} // ReactPaginate→0始まり CakePHP→1始まり
                onPageChange={handlePageChange}
                pageRangeDisplayed={5}
                pageCount={paging?.pageCount}
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                activeLinkClassName="uk-text-emphasis"
                previousLabel='<'
                nextLabel='>'
                breakLabel='...'
              />
              <a onClick={() => {
                onPageChange(String(paging?.pageCount))
              }}>LAST</a>
            </div>
          </div>
        </div>
      </div>
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
                navigate("/drm_pf/info");
              }}
          />
      }
    </div>
  );
}
