import React, { useEffect, useState, ChangeEvent } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import SubHeader from "@/components/SubHeader";
import { useLoadingStore } from "@/stores/loading";
import {axios} from '@/lib/axios';
import {useMessageModalStore} from '@/stores/message'
import CustomSelect from '@/components/CustomSelect';
import { TextBox } from "@/components/Form/TextBox";
import { TextArea } from "@/components/Form/TextArea";
import { RadioButton } from "@/components/Form/RadioButton";
import ConfirmModal from "@/components/ConfirmModal";

import {
  MESSAGE_NO_E03,
  MESSAGE_NO_E08,
  MESSAGE_NO_E21,
  MESSAGE_NO_E35,
  MESSAGE_NO_E36,
  MESSAGE_NO_E37,
  MESSAGE_NO_E39,
  BUSINESS_ENTITY_TYPE,
  BILLING_POST_TYPE,
  AGREE_CHECK,
  MESSAGE_NO_E67,
} from "@/config";

export interface CustomerInfo {
  id?: number,
  customer_number?: string,
  customer_type_id?: string,
  customer_group_type_id?: string,
  group_name?: string,
  department_name?: string,
  unit_name?: string,
  section_name?: string,
  position?: string,
  last_name?: string,
  first_name?: string,
  reading_last_name?: string,
  reading_first_name?: string,
  phone_number?: string,
  emergency_phone_number?: string,
  zipcode?: string,
  pref?: string,
  city?: string,
  house_number?: string,
  building_name?: string,
  email_address?: string,
  login_id?: string,
  is_personal_information_handling?: string,
  remarks?: string,
  customer_type?: string,
  customer_group_type?: string,
  password?: string,
}
interface CustomerTypes {
  value: string,
  label: string,
}
interface CustomerGroupTypes {
  value: string,
  label: string,
}
interface CustomerPaymentTypes {
  value: string,
  label: string,
}

export default function BasicInfoNew() {
  const location = useLocation();
  const isEdit = location.pathname.includes('/edit');
  const isNew = location.pathname.includes('/new');
  const navigate = useNavigate();

  const breadCrumbs = [
    {title: '顧客一覧', path: '/customer'},
    {title: '新規作成'}
  ];

  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
  const [customerTypes, setCustomerTypes] = useState<CustomerTypes[]>([]);
  const [customerGroupTypes, setCustomerGroupTypes] = useState<CustomerGroupTypes[]>([]);
  const [customerPaymentTypes, setCustomerPaymentTypes] = useState<CustomerPaymentTypes[]>([]);
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
  const setLoading = useLoadingStore(state => state.setLoading);
  const [password, setPassword] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<CustomerInfo>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: customerInfo,
      values: customerInfo,
    }
  );
  const hasErrors = Object.keys(errors).length > 0;

  const onClickCancel = () => {
    setIsShowBackModal(true);
  }

  const onClickSave = () => {
    handleSubmit(onSubmit)();
  }

  // 保存処理
  const onSubmit: SubmitHandler<CustomerInfo> = ( data ) => {
    setEmailErrorMessage(null);
    console.log(data);
    setLoading(true)
    axios.post('/api/v1/customers/setCustomer', data)
    .then(result => {
      console.log(result);
      console.log(result.data.response.id);
      console.log("登録しました")
      //navigate(-1)
      navigate('/customer/basicinfo/detail/' + result.data.response.id);
    }).catch(error => {
      console.log("失敗しました");
      if (error.response) {
        if (error.response.data['message'] == MESSAGE_NO_E21) {
          setEmailErrorMessage(MESSAGE_NO_E21);
        } else {
          setErrorMessage(MESSAGE_NO_E37);
        }
      }
    }).finally(() => {
      setLoading(false);
    });
  }


  // 郵便番号⇒住所検索
  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newZipcode = e.target.value;

    // APIリクエストを送信
    axios.get(`/api/v1/proxy/getAddress?zipcode=${newZipcode}`)
      .then(response => {
        const results  = response.data.results;
        console.log("----- zipcode search ------");
        console.log(results);
        if (results) {
          setValue('pref', results[0].address1);
          setValue('city', results[0].address2);
          setValue('house_number', results[0].address3);
          setValue('building_name', '');
        } else {
          alert('該当する住所が見つかりませんでした');
        }
      })
      .catch(error => {
        console.error('Error fetching address:', error);
      });
  };

  useEffect(() => {
    axios.get('/api/v1/customers/getCustomerTypes')
    .then(result => {
      setCustomerTypes(result.data.customerTypes);
      setCustomerGroupTypes(result.data.customerGroupTypes);
      setCustomerPaymentTypes(result.data.customerPaymentTypes);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage])

  return (
    <div className="uk-padding-small">
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="新規作成" showPrompt/>
        <ul className="uk-tab uk-margin-small-top">
          <li className="uk-active"><Link to={`/customer/basicinfo/new`}>基本情報</Link></li>
          <li><Link to="">DRM-PF</Link></li>
          <li><Link to="">チケット</Link></li>
        </ul>
        <div className="uk-text-right uk-margin-small-top">
          <button className="uk-button--m uk-button-cancel" onClick={onClickCancel}>キャンセル</button>
          <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
        </div>
      </div>
      <div className="container uk-margin-large-bottom">
        <div className="info-tbl_wrap">
          <form>
            {/*<div className="error-box">入力エラーがあります</div>*/}
            <div className="precaution">※は必須です</div>

            <table className="customer-info-tbl uk-width-1-1">
              <tbody>
              <tr>
                <th className="label">顧客番号</th>
                <td className="content"> </td>
              </tr>
              <tr>
                <th className="label">道路管理者区分<span className="precaution">※</span></th>
                <td className="content">
                  <div className="uk-child-width-auto uk-grid">
                    <RadioButton
                      name="customer_type_id"
                      options={customerTypes}
                      register={register}
                      rules={{ required: MESSAGE_NO_E08 }}
                      errors={errors}
                    />
                  </div>
                  {errors.customer_type_id && <p className="precaution">{errors.customer_type_id.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">団体区分<span className="precaution">※</span></th>
                <td className="content">
                  <div className="uk-grid-small uk-grid">
                    <RadioButton
                      name="customer_group_type_id"
                      options={customerGroupTypes}
                      register={register}
                      rules={{ required: MESSAGE_NO_E08 }}
                      errors={errors}
                    />
                  </div>
                  {errors.customer_group_type_id && <p className="precaution">{errors.customer_group_type_id.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">法人・団体名<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="group_name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 100, message: MESSAGE_NO_E35(100) },
                      }}
                      errors={errors}
                      placeholder={"法人・団体名を入力してください"}
                    />
                  </div>
                  {errors.group_name && <p className="precaution">{errors.group_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">部署名・所属<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="department_name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 100, message: MESSAGE_NO_E35(100) },
                      }}
                      errors={errors}
                      placeholder={"部署名・所属を入力してください"}
                    />
                  </div>
                  {errors.department_name && <p className="precaution">{errors.department_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">課名</th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="section_name"
                      register={register}
                      rules={{
                        maxLength: { value: 100, message: MESSAGE_NO_E35(100) },
                      }}
                      errors={errors}
                      placeholder={"課名を入力してください"}
                    />
                  </div>
                  {errors.section_name && <p className="precaution">{errors.section_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">室名または係名</th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="unit_name"
                      register={register}
                      rules={{
                        maxLength: { value: 100, message: MESSAGE_NO_E35(100) },
                      }}
                      errors={errors}
                      placeholder={"室名または係名を入力してください"}
                    />
                  </div>
                  {errors.unit_name && <p className="precaution">{errors.unit_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">役職</th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="position"
                      register={register}
                      rules={{
                        maxLength: { value: 100, message: MESSAGE_NO_E35(100) },
                      }}
                      errors={errors}
                      placeholder={"役職を入力してください"}
                    />
                  </div>
                  {errors.position && <p className="precaution">{errors.position.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">姓<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="last_name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 30, message: MESSAGE_NO_E35(30) },
                      }}
                      errors={errors}
                      placeholder={"姓を入力してください"}
                    />
                  </div>
                  {errors.last_name && <p className="precaution">{errors.last_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">名<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="first_name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 30, message: MESSAGE_NO_E35(30) },
                      }}
                      errors={errors}
                      placeholder={"名を入力してください"}
                    />
                  </div>
                  {errors.first_name && <p className="precaution">{errors.first_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">せい<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="reading_last_name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 50, message: MESSAGE_NO_E35(50) },
                      }}
                      errors={errors}
                      placeholder={"せいを入力してください"}
                    />
                  </div>
                  {errors.reading_last_name && <p className="precaution">{errors.reading_last_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">めい<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="reading_first_name"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 50, message: MESSAGE_NO_E35(50) },
                      }}
                      errors={errors}
                      placeholder={"めいを入力してください"}
                    />
                  </div>
                  {errors.reading_first_name && <p className="precaution">{errors.reading_first_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">電話番号<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="phone_number"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 20, message: MESSAGE_NO_E35(20) },
                      }}
                      errors={errors}
                      placeholder={"電話番号を入力してください"}
                    />
                  </div>
                  {errors.phone_number && <p className="precaution">{errors.phone_number.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">緊急連絡先（携帯電話等）</th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="emergency_phone_number"
                      register={register}
                      rules={{
                        maxLength: { value: 20, message: MESSAGE_NO_E35(20) },
                      }}
                      errors={errors}
                      placeholder={"緊急連絡先（携帯電話等）を入力してください"}
                    />
                  </div>
                  {errors.emergency_phone_number && <p className="precaution">{errors.emergency_phone_number.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">郵便番号<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <input 
                      {...register('zipcode', {
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 7, message: MESSAGE_NO_E35(7) },
                      })}
                      type="text" 
                      name="zipcode" 
                      placeholder={"郵便番号を入力してください"}
                      onBlur={handleZipcodeChange}
                    />                    
                  </div>
                  {errors.zipcode && <p className="precaution">{errors.zipcode.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">都道府県<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <input 
                      {...register("pref", {
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 4, message: MESSAGE_NO_E35(4) },
                      })} 
                      type="text"
                      name="pref"
                      placeholder="都道府県を入力してください"
                      readOnly
                    />
                  </div>
                  {errors.pref && <p className="precaution">{errors.pref.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">市区町村<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <input 
                      {...register("city", {
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 56, message: MESSAGE_NO_E35(56) },
                      })} 
                      type="text"
                      name="pref"
                      placeholder="市区町村を入力してください"
                      readOnly
                    />
                  </div>
                  {errors.city && <p className="precaution">{errors.city.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">町名番地<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="house_number"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 56, message: MESSAGE_NO_E35(56) },
                      }}
                      errors={errors}
                      placeholder={"町名番地を入力してください"}
                    />
                  </div>
                  {errors.house_number && <p className="precaution">{errors.house_number.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">建物名・部屋番号</th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="building_name"
                      register={register}
                      rules={{
                        maxLength: { value: 60, message: MESSAGE_NO_E35(60) },
                      }}
                      errors={errors}
                      placeholder={"建物名・部屋番号を入力してください"}
                    />
                  </div>
                  {errors.building_name && <p className="precaution">{errors.building_name.message}</p>}
                </td>
              </tr>
              <tr>
                <th className="label">メールアドレス<span className="precaution">※</span></th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="email_address"
                      register={register}
                      rules={{
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 254, message: MESSAGE_NO_E35(254) },
                      }}
                      errors={errors}
                      placeholder={"メールアドレスを入力してください"}
                    />
                  </div>
                  {errors.email_address && <p className="precaution">{errors.email_address.message}</p>}
                  {emailErrorMessage && <p className="precaution">{emailErrorMessage}</p>}
                </td>
              </tr>
              </tbody>
            </table>

            <table className="customer-info-tbl uk-width-1-1">
              <tbody>
              <tr>
                <th className="label">個人情報取扱い</th>
                <td className="content">
                  <label><input 
                    {...register('is_personal_information_handling')}
                      className="uk-margin-small-right" 
                    type="checkbox" 
                    name="is_personal_information_handling" 
                    value={AGREE_CHECK} 
                  />1.同意する</label>
                </td>
              </tr>
              <tr>
                <th className="label">ログインid</th>
                <td className="content"> </td>
              </tr>
              <tr>
                <th className="label">
                  パスワード<span className="precaution">※</span><br/>
                </th>
                <td className="content">
                  <div className="width-40p uk-flex">
                    <input 
                      {...register('password', {
                        required: MESSAGE_NO_E08,
                        maxLength: { value: 16, message: MESSAGE_NO_E35(16) } ,
                        minLength: { value: 6, message: MESSAGE_NO_E67(6) } ,
                        pattern: {
                          value: /^[a-zA-Z0-9_-]+$/,
                          message: MESSAGE_NO_E03
                        }
                      })}
                      type="password" 
                      className="uk-width-1-1" 
                      name="password" 
                      placeholder={"パスワードを入力してください"}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  {errors.password && <p className="precaution">{errors.password.message}</p>}
                </td>
              </tr>
              </tbody>
            </table>

            <table className="customer-info-tbl uk-width-1-1">
              <tbody>
              <tr>
                <th className="label">備考（内部用）</th>
                <td className="content">
                  <div className="uk-flex">
                    <TextArea
                      name="remarks"
                      register={register}
                      rules={{
                        maxLength: { value: 500, message: MESSAGE_NO_E35(500) },
                      }}
                      errors={errors}
                      placeholder={"備考（内部用）を入力してください"}
                    />
                  </div>
                  {errors.remarks && <p className="precaution">{errors.remarks.message}</p>}
                </td>
              </tr>
              </tbody>
            </table>

          </form>
        </div>
        <ConfirmModal
          text="登録をキャンセルしますか？入力したデータは登録されません。"
          confirmButtonText="前の画面へ"
          isShow={isShowBackModal}
          onConfirm={() => {
            navigate(-1)
          }}
          onCancel={() => {
            setIsShowBackModal(false);
          }}
        />
        {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }

      </div>
    </div>
  );
}
