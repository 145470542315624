import { useState, useEffect } from "react";
import { getProjects } from "@/api/projects";
import { Project } from "@/stores/project";
import {
  getTicketDestinations,
  addTicketDestination,
  removeTicketDestination,
  modifyTicketDestination,
} from "@/api/ticketDestination";
import {TicketDestinations} from "@/components/Ticket/types";

export const useTicketDestinations = (
  setLoading: (isLoading: boolean) => void,
  onError: () => void
) => {
  const [projects, setProjects] = useState<Project[]>();
  const [ticketDestinations, setTicketDestinations] =
    useState<TicketDestinations[]>();

  useEffect(() => {
    setLoading(true);
    Promise.all([getProjects(), getTicketDestinations()])
      .then((res) => {
        setProjects(
          res[0].data.projects.filter((item: Project) =>
            [2, 3, 4, 90].includes(item.id)
          )
        );
        setTicketDestinations(res[1].data.ticketDestinations);
      })
      .catch(() => {
        onError();
      })
      .finally(() => setLoading(false));
  }, []);

  const addTicketDest = async (ticketDestination: TicketDestinations) => {
    setLoading(true);

    try {
      const res = await addTicketDestination(ticketDestination);
      const newTicketDest = {
        ...ticketDestination,
        id: res.data.id,
      };
      setTicketDestinations([...(ticketDestinations ?? []), newTicketDest]);
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  const editTicketDest = async (ticketDestination: TicketDestinations) => {
    setLoading(true);

    try {
      const res = await modifyTicketDestination(ticketDestination);
      const modifyTicketDest = {
        ...ticketDestination,
        id: res.data.id,
      };

      const cp = ticketDestinations;
      if (cp !== undefined) {
        const index = cp.findIndex(item => item.id === modifyTicketDest.id);
        cp[index] = modifyTicketDest;
        setTicketDestinations(cp);
      }
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  const removeTicketDest = async (ticketDestination: TicketDestinations) => {
    setLoading(true);

    try {
      await removeTicketDestination(ticketDestination);
      setTicketDestinations(
        ticketDestinations?.filter((t) => t.id !== ticketDestination.id)
      );
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  return { projects, ticketDestinations, addTicketDest, editTicketDest,removeTicketDest };
};
